body .dropdown-menu {
    --bs-dropdown-bg: #131418;
    right: 0;
    left: auto!important;
}
.dropdown-item {
    --bs-dropdown-link-color:#fff;
}
.dropdown-item:focus, .dropdown-item:hover {
    --bs-dropdown-link-hover-color:#fff;
    --bs-dropdown-link-hover-bg:#000;
}
.dropdown-item.active, .dropdown-item:active {
    --bs-dropdown-link-active-bg:#d7a519;
}
.border {
    --bs-border-color:#d7a519;
}
.border-primary{
    --bs-primary-rgb: #d7a519;
}