.card-sections .nav-pills .nav-link {
  background: 0 0;
  border: 0;
  /* padding: 10px 30px; */
  color: #fff;
  border-radius: 25px!important;
  font-size: 19px;
  font-family: 'rajdhani';
}
body .navbar-nav .nav-link {
  color: #fff!important;
  margin-right: 12px;
  font-size: 18px;
}
body .navbar-nav .nav-link:focus,body .navbar-nav .nav-link:active, body .navbar-nav .nav-link:hover {
  color:#d7a519!important;
}
.card-sections .nav-pills .nav-link.active, .card-sections .nav-pills .show>.nav-link{
  background-color: transparent;
  color: #d7a519;
}
.card-sections .nav-pills {
  border-bottom: 1px solid #41444a;
  padding-bottom: 3px;
}

.table>thead {
  background: rgba(255, 255, 255, 0.05);
}
tbody, td, tfoot, th, thead, tr {
  border-color: #222121!important;
}
table th, table td {
  color: #fff!important;
  vertical-align: middle;
  padding: 10px 20px!important;
}
table th, table td{
  color: #fff!important;
  vertical-align: middle;
  padding: 20px;
  white-space: nowrap;
}
.text-right {
  text-align: right!important;
}
/* ------------------------ */
.card {
  background: #000000!important;
  padding: 30px;
  box-shadow: 0px 0px 4px 0px #605f5fa1;
}
.activity-name img {
  height: 52px;
  width: 52px;
  margin-right: 8px;
  border-radius: 8px;
}
.activity-name h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #fff;
}
.activity-name .rounded {
  border-radius: 50%!important;
}
.btn-gradient-1 {
  /* border: 4px solid;
  border-radius: 26px;
  border: 6px solid transparent;
  background: linear-gradient(45deg,#d7a519,#4c390c) border-box;
  background-color: #fff;

  mask-composite: exclude; */
}
.btn-gradient-1 .entry-ticket{
  background-color: rgb(13 13 13);
  margin-bottom: 0px;
}
.btn-gradient-1::after {

}
/* ---------------- */
.em_bar_bg {
  height: 5px;
  width: 100px;
  background: #D0DEFF;
  margin: 16px auto 30px;
  position: relative;
  margin-left: unset;
  border-radius: 30px;
  animation: 1s 2s fadeInLeft both;
}
.em_bar_bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: -4px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background:linear-gradient( to right, #ce9a24, #c99a17, 46%, #6d5725 65%);
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
            transform: translateX(88px);
  }
}
@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
            transform: translateX(88px);
  }
}
/* ------------Contact us----------------- */
.box-rounded {
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
.padding40 {
  padding: 40px;
}
.mb30 {
  margin-bottom: 30px;
}

.bg-color {
  background: rgb(13 13 13);
}
.address ul {
  list-style: none;
  padding-left: 0px;
}
.address ul li {
  margin-bottom: 5px;
}
.address ul li i {
  font-size: 15px;
  margin-right: 10px;
  color: #d7a519;
}
.address ul li a{
  color: #d7a519;
}
input.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  height: 45px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #222324;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
select.form-select {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  border: 1px solid #222324;
  color: #fff;
}
select.form-select:focus {
  border-color: #82640f;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}
textarea.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #222324;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus {
  color: #fff!important;
  background-color: #000000!important;
  border-color: #252933!important;
  outline: 0;
  box-shadow: none!important;
}
.themebtn {
  padding: 10px 30px!important;
  border-radius: 10px!important;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  border-color: #d7a519!important;
}
/* ------------Modal Css Start--------------- */
.modal-dark .modal-content {
  box-shadow: 0px 0px 10px 0px #474545;
  background-color: #000;
}
.modal-dark .modal-header {
  border-bottom: 1px solid #222324;
}
.modal-dark .modal-header {
  border-bottom: none;
  padding: 25px 25px;
}
.btn-close {
  border: 1px solid #d7a519!important;
  background-size: 11px;
}
.n-multiplier {
  display: inline-flex;
  align-items: center;
}
.modal-dark .modal-header p {
  margin-bottom: -28px;
  color: #fff;
  font-size: 18px;
}
.n-multiplier input {
  width: 30%;
  height: 40px;
  padding: 0 0px;
  text-align: center;
  font-family: 'rajdhani';
  font-size: 1.4rem;
}
.n-multiplier span {
  font-size: 18px;
  padding: 0 10px;
}
.n-multiplier h4{
  margin-bottom: 0px;
}
/* ------------------------- */
.k-btn img{
  height: 20px;
    width: 20px;
    filter: brightness(0)invert(1);
}
.k-btn button {
  font-weight: bold;
  border: 1px solid #d7a519 !important;
  padding: 5px 27px;
  display: block;
  margin: 10px auto;
}
.head {
  background: #d7a519;
  padding: 8px 0px;
  margin-bottom: 30px;
  border-radius: 8px;
}
.head h4{
   margin-bottom: 0px;
}