#wheel canvas {}

div#wheel {
    max-height: 100%;
}

.spinthewheel .modal-content {
    background: transparent;
    box-shadow: none;
}

.spinthewheel {
    overflow-y: hidden;
}

.spinthewheel .modal-header {
    border: none;
}

.spinthewheel .modal-header .btn-close {
    display: none;
}

.amountdetail {
    margin-top: -40px;
}

.spinbox .btn-gradient-1 .entry-ticket {
    /* background-color: rgb(13 13 13); */
    margin-bottom: 0px;
    /* background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,linear-gradient(90deg, #1b1b1b 10px, transparent 10px),linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424); */
    /* background-color: #131313; */
    background-size: 15px 12px;
}

.spinbox .entry-ticket h1 {
    font-size: 35px;
    text-shadow: 0px 0px 27px #715b1b;
    margin-top: -43px;
    /* background: #d7a51975; */
    padding: -3px 0px;
    border-radius: 50px 50px 50px 50px;
}

#wheel canvas {
    width: 100%;
}

@media screen and (min-width: 991px) {
    #wheel canvas {
        /* width: 100%; */
        width: 70%;
        margin: 0px auto;
        display: flex;
    }

   


}

@media only screen and (max-width: 991px) {
    .spinthewheel {
        overflow-y: auto;
    }

    #wheel canvas {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 768px) {
    #wheel canvas {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 500px) {
    #wheel canvas {
        width: 100%;
        height: 100%;
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 460px) {
    #wheel canvas {
        width: 100%;
        height: 100%;
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 425px) {
    #wheel canvas {
        width: 100%;
        height: 100%;
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 425px) {
    #wheel canvas {
        width: 100%;
        height: 100%;
        transform: scale(1.1);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        /* Adjust the number of degrees for a single rotation */
    }
}

.wheel {
    animation: spin 10s linear infinite;
    /* Adjust the animation duration to control speed (e.g., 10s for a slower rotation) */
}