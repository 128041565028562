.buykai-m{
    min-height: 400px;
}
.f-logo img {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 200px;
}
body .btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #22cd21;
    --bs-btn-border-color: #22cd21;
    --bs-btn-hover-color: #22cd21;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #22cd21;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #22cd21;
    --bs-btn-active-border-color: #22cd21;
    --bs-btn-disabled-bg: #22cd21;
    --bs-btn-disabled-border-color: #22cd21;
    

}
body .btn.btn-success:hover{
    border-color:#22cd21;
    
}